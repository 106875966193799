import React from "react";
import "./Loader.css"; // Import your loader styles

interface LoaderProps {
  message?: string;
}

const Loader: React.FC<LoaderProps> = ({ message = "Loading..." }) => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
      <p className="loader-message">{message}</p>
    </div>
  );
};

export default Loader;
