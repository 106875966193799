import { createContext, useContext, useState } from "react";

interface ThemeProps {
  color: string;
  bgcolor: string;
}

interface ThemeContextProps {
  theme: ThemeProps;
  toggleTheme: () => void;
  darkTheme: boolean;
}

const ThemeContext = createContext<ThemeContextProps>({
  theme: {
    color: "text-black",
    bgcolor: "bg-white",
  },
  toggleTheme: () => {},
  darkTheme: false,
});

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [darkTheme, setDarkTheme] = useState<boolean>(false);

  const toggleTheme = () => {
    setDarkTheme((prev) => !prev);
  };

  const theme: ThemeProps = {
    color: darkTheme ? "text-blue-500" : "text-black",
    bgcolor: darkTheme ? "bg-gray-800" : "bg-white",
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, darkTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};
