import { useTheme } from "../../hooks/ThemeContext";
import IconButton from "../iconButton/IconButton";

const Footer = () => {
  const { darkTheme } = useTheme();
  return (
    <div
      className={`flex w-full justify-between items-center sm:px-[25%] px-4 pt-6 pb-14 border-t shadow-xl ${
        darkTheme ? "border-gray-700 text-white" : "border-gray-100 text-black"
      }`}
    >
      <div className="flex flex-col gap-4">
        <div className="font-semibold">Contact Us</div>
        <div className="flex gap-4">
          <IconButton
            link="https://www.instagram.com/akazi_designs/"
            imgSrc="footer/instagram.png"
          />
          <IconButton
            link="https://wa.me/+918089383086"
            imgSrc="footer/whatsapp.png"
          />
          <IconButton
            link="mailto:akazidesigns4u@gmail.com"
            imgSrc="footer/gmail.png"
          />
        </div>
      </div>
      <div className="font-bold text-transparent text-xl bg-clip-text bg-gradient-to-r from-black to-yellow-500">
        Akazi Designs
      </div>
    </div>
  );
};

export default Footer;
