import ContentBox from "./ContentBox";
import Loader from "../loader/Loader";
import { HighliteContext } from "../../constants/imageTextLayout";

const ImageTextLayout = () => {
  const imageList = [
    "images/layout/image1.png",
    "images/layout/image2.png",
    "images/layout/image3.png",
  ];

  if (imageList.length === 0) {
    return <Loader />;
  }

  return (
    <div>
      <div className="flex justify-start w-full font-bold text-xl pt-12 px-2">
        Highlites
      </div>
      {/* <div className="flex justify-start w-full font-bold text-xl pt-12 px-2">
            {HighliteContextTitle[index]}
          </div> */}
      {imageList.map((image, index) => (
        <ContentBox
          imageUrl={image}
          text={HighliteContext[index]}
          reverseBox={index % 2 === 1}
          key={image}
        />
      ))}
    </div>
  );
};

export default ImageTextLayout;
