import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Carousel.css";
import Loader from "../loader/Loader";

const SliderCarousel = () => {
  const imageList = [
    // "images/carousel/image1.png",
    "images/carousel/image2.png",
    "images/carousel/image3.png",
    "images/carousel/image4.png",
    "images/carousel/image5.png",
    "images/carousel/image6.png",
  ];

  if (imageList.length === 0) {
    return <Loader />;
  }

  return (
    <Carousel
      autoPlay
      infiniteLoop
      showStatus={false}
      showThumbs={false}
      showIndicators={false}
      centerMode
      centerSlidePercentage={60}
      swipeable
    >
      {imageList.map((image, index) => (
        <div
          key={index}
          className={`flex justify-center items-center w-full h-[200px] sm:h-[360px] shadow-md`}
        >
          <img
            src={image}
            alt={`Carousel ${index}`}
            className="h-full w-fit object-cover object-top rounded sm:px-12"
          />
        </div>
      ))}
    </Carousel>
  );
};

export default SliderCarousel;
