export const HighliteContext = [
  "Childhood is a precious and fleeting time filled with unforgettable moments. Our Children's Collection is thoughtfully crafted to complement these moments, ensuring that your child looks and feels their best during every adventure, celebration, and day of discovery.",
  "Every bride deserves to feel like royalty on her wedding day, and that's precisely what our Wedding Design Collection aims to deliver. Meticulously handcrafted by our talented designer, each gown in this collection is a masterpiece designed to make you look and feel your absolute best.",
  "Our Latest Trends Collection showcases the hottest styles, colors, and patterns that are making waves in the fashion world. Whether you're a trendsetter or simply looking to update your wardrobe, you'll find pieces that perfectly capture the spirit of the season.",
];

export const HighliteContextTitle = [
  "Cherish the Moments",
  "Crafted for Unforgettable Moments",
  "Trendsetting Styles",
];
