import { BrowserRouter, Route, Routes } from "react-router-dom";
import Empty from "./pages/Empty";
import { ThemeProvider } from "./hooks/ThemeContext";
import { ErrorBoundary } from "react-error-boundary";
import Error from "./pages/Error";
import { Suspense, lazy } from "react";
import { Loader } from "./components";

const Home = lazy(() => import("./pages/Home"));

const App = () => {
  return (
    <ThemeProvider>
      <ErrorBoundary fallback={<Error />}>
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="*" element={<Empty />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
