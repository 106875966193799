import ExpandableTextField from "../expandableTextField/ExpandableTextField";

interface ContentBoxProps {
  imageUrl: string;
  text: string;
  reverseBox?: boolean;
}

const ContentBox = ({
  imageUrl,
  text,
  reverseBox = false,
}: ContentBoxProps) => {
  return (
    <div
      className={`flex justify-between items-center gap-2 w-full py-4 flex-col sm:flex-row ${
        reverseBox && "sm:flex-row-reverse"
      }`}
    >
      <div className="w-full relative items-center flex justify-center">
        <img
          src={imageUrl}
          alt="home"
          className="max-h-[360px] w-3/5 mx-2 sm:w-full rounded object-contain"
        />
        {/* <div className="absolute w-3/5 sm:w-full max-h[360px] h-full top-0 bg-gradient-radial from-transparent from-70% to-white to-100%" /> */}
      </div>
      <ExpandableTextField text={text} />
    </div>
  );
};

export default ContentBox;
