interface IconButtonProps {
  link: string;
  imgSrc: string;
}

const IconButton = ({ link, imgSrc }: IconButtonProps) => {
  return (
    <div className="cursor-pointer" onClick={() => window.open(link, "_blank")}>
      <img src={imgSrc} alt="logo" className="w-8 h-8 rounded" />
    </div>
  );
};

export default IconButton;
