const Empty = () => {
  return (
    <div className="flex flex-col items-center mt-4 gap-4">
      <h1>Oops! Page Not Found</h1>
      <p>
        The URL you entered is incorrect. Please check the URL and try again.
      </p>
    </div>
  );
};

export default Empty;
