import { useTheme } from "../../hooks/ThemeContext";
// import ThemeSwitch from "../themeSwitch/ThemeSwitch";

const NavBar = () => {
  const { darkTheme } = useTheme();
  return (
    <div
      className={`z-50 shadow-md w-full justify-between  items-center sm:px-[25%] px-4 flex h-18 min-h-[72px] fixed top-0 py-4 ${
        darkTheme
          ? "bg-gray-900 text-white"
          : " bg-gradient-to-r from-pink-100 to-white text-black"
      }`}
    >
      <div className="flex items-center gap-4">
        <img
          src="logo.png"
          alt="logo"
          className={`w-8 h-8 rounded-2xl shadow-sm border-none ${
            darkTheme ? "border-gray-500" : "border-gray-100"
          }`}
        />
        <div className="font-bold text-transparent text-xl bg-clip-text bg-gradient-to-r from-black to-yellow-500">
          Akazi Designs
        </div>
      </div>
      {/* <ThemeSwitch /> */}
    </div>
  );
};

export default NavBar;
