import { useEffect, useState } from "react";
import "./ExpandableText.css";

interface ExpandableTextFieldProps {
  text: string;
}

const ExpandableTextField = ({ text }: ExpandableTextFieldProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

//   const maxHeight = `max-h-[${expandableText?.scrollHeight || 0}]`;

  useEffect(() => {
    const expandableText = document.querySelector(
      "#expandableText"
    ) as HTMLElement;
    const readMoreButton = document.querySelector(
      "#readMoreButton"
    ) as HTMLAnchorElement;
    if (expandableText && readMoreButton) {
      if (expandableText.scrollHeight > 200)
        readMoreButton.style.display = "block";
    }
  }, []);

  return (
    <div className="flex flex-col w-full p-4 ">
      <div
        className={`w-full overflow-hidden text-justify duration-300 expandableText ${
          isExpanded ? "max-h-fit" : "max-h-[200px]"
        }`}
        id="expandableText"
      >
        <p className="text-lg">{text}</p>
      </div>
      <div
        className=" text-gray-500 cursor-pointer hidden w-fit"
        onClick={() => setIsExpanded((prev) => !prev)}
        id="readMoreButton"
      >
        {isExpanded ? "Read less" : "Read more"}
      </div>
    </div>
  );
};

export default ExpandableTextField;
